/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { Icon, Grid, Divider } from 'semantic-ui-react'
// static files
import Logo from '../images/logos/contego-logo.png'
// components
import NewsletterForm from './NewsletterForm'
// version
import { version } from '../../package.json'

const Footer = () => {
  const goToTop = React.useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <footer className="container-fluid px-0 mt-4">
      <div className="row shadow py-4">
        <div className="col">
          <Grid columns="16" className="py-4">
            <Grid.Column mobile="16" tablet="16" computer="16" widescreen="7" largeScreen="7">
              <h1>OUR SHOWROOMS</h1>

              <Grid columns="16">
                <Grid.Column mobile="16" tablet="8" computer="8" widescreen="8" largeScreen="8">
                  <h4 className="w-100 text-uppercase">Garden Grove, CA</h4>
                  <a
                    href="https://goo.gl/maps/z7iY7nsy7sLVv1f28"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-medium text-secondary"
                  >
                    12856 Brookhurst St <br /> Garden Grove, CA 92840
                  </a>
                  <br />
                  <br />
                  <p className="d-block mb-0">
                    <strong className="text-secondary">MON-FRI</strong> 10:00AM - 6:00PM (PT)
                  </p>
                  <p className="d-block mb-0">
                    <strong className="text-secondary">SAT</strong> 10:00AM - 3:00PM (PT)
                  </p>
                  <p className="d-block mb-0">
                    <strong className="text-secondary">SUN</strong> 10:00AM - 5:00PM (PT)
                  </p>
                  <br />
                  <a className="text-secondary text-medium" href="tel:7148958865">
                    (714) 895-8865
                  </a>
                </Grid.Column>

                <Grid.Column mobile="16" tablet="8" computer="8" widescreen="8" largeScreen="8">
                  <h4 className="w-100 text-uppercase">Houston, TX</h4>
                  <a
                    href="https://goo.gl/maps/tS79wr6Dnm8vazNf8"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-medium text-secondary"
                  >
                    6787A Wilcrest Dr Suite A <br /> Houston, TX 77072
                  </a>
                  <br />
                  <br />
                  <p className="d-block mb-0">
                    <strong className="text-secondary">MON-FRI</strong> 10:00AM - 6:00PM (CT)
                  </p>
                  <p className="d-block mb-0">
                    <strong className="text-secondary">SAT</strong> 11:00AM - 5:00PM (CT)
                  </p>
                  <p className="d-block mb-0">
                    <strong className="text-secondary">SUN</strong> 11:00AM - 6:00PM (CT)
                  </p>
                  <br />
                  <a className="text-secondary text-medium" href="tel:7148958865">
                    (714) 895-8865
                  </a>
                </Grid.Column>
              </Grid>
            </Grid.Column>

            <Grid.Column mobile="16" tablet="8" computer="7" widescreen="4" largeScreen="4">
              <h1>QUICK LINKS</h1>
              <ul className={classNames('nav-bar', 'd-flex flex-wrap p-0')}>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/faq/general"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    F.A.Q
                  </a>
                </li>

                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/manuals-and-downloads/magna"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    User Manuals
                  </a>
                </li>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/contact-us"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    Contact Us
                  </a>
                </li>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/pedicure-spa-chairs/magna"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    Pedicure Spa Massage Chairs
                  </a>
                </li>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/accessories"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    Massage Chair Accessories
                  </a>
                </li>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/purchasetermsandconditions"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    Purchase Terms & Conditions
                  </a>
                </li>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/limitedwarranty"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    Limited Warranty
                  </a>
                </li>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/privacypolicy"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/shippingterms"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    Shipping Terms
                  </a>
                </li>
                <li className={classNames('nav-bar-item', 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/deliveryandhook-upservice"
                    className={classNames('nav-bar-link', 'py-2')}
                  >
                    Delivery & Hook-up Service
                  </a>
                </li>
              </ul>
            </Grid.Column>

            <Grid.Column mobile="16" tablet="8" computer="7" widescreen="4" largeScreen="4">
              <NewsletterForm />
            </Grid.Column>

            <Grid.Column mobile="16" tablet="16" computer="2" widescreen="1" largeScreen="1">
              <div className="scroll-top-container">
                <button
                  type="button"
                  className="action-button mb-2 rounded py-2 mx-2"
                  onClick={goToTop}
                  label="go to top"
                >
                  <Icon name="angle double up" className="mr-0" />
                </button>
                <a
                  href="https://www.facebook.com/contegospa/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="mb-2 mr-2"
                  aria-label="facebook link"
                >
                  <Icon size="large" name="facebook official" />
                </a>
                <a
                  href="https://www.instagram.com/contegospa/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="mb-2 mr-2"
                  aria-label="instagram link"
                >
                  <Icon size="large" name="instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC7kqMnIleOcvV76jwmNb7Rg/featured"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="mb-2 mr-2"
                  aria-label="youtube link"
                >
                  <Icon size="large" name="youtube play" />
                </a>
              </div>
            </Grid.Column>
          </Grid>

          <Divider />

          <Grid columns="3" stackable textAlign="center" verticalAlign="middle">
            <Grid.Column>
              <h6>
                © {new Date().getFullYear()} Contego Spa Design Inc. All Rights Reserved. Version{' '}
                {version}
              </h6>
            </Grid.Column>

            <Grid.Column>
              <Link to="/">
                <img
                  src={Logo}
                  alt="contego logo"
                  className="my-2 mr-4 selectable"
                  width="200px"
                  height="auto"
                />
              </Link>
            </Grid.Column>

            <Grid.Column>
              <a className="mr-4" href="https://www.contegospa.com/purchasetermsandconditions">
                Purchases Terms & Conditions
              </a>
              <a className="mr-4" href="https://www.contegospa.com/limitedwarranty">
                Limited Warranty
              </a>
              <a className="mr-4" href="https://www.contegospa.com/privacypolicy">
                Privacy Policy
              </a>
              <a className="mr-4" href="https://www.contegospa.com/shippingterms">
                Shipping Terms
              </a>
              <a className="mr-4" href="https://www.contegospa.com/deliveryandhook-upservice">
                Delivery & Hook-up Service
              </a>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </footer>
  )
}

export default Footer
