import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Form, Grid, Message, Progress } from 'semantic-ui-react'
import ReCAPTCHA from 'react-google-recaptcha'
import TextInput from './TextInput'

import { subscribeNewsletter } from '../services/bullarum.service'

const NewsletterSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format')
})

const NewsletterForm = () => {
  const recaptchaRef = React.createRef()
  const [hasError, setHasError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')

  const executeRecaptcha = () => {
    recaptchaRef.current.execute()
  }

  const handleExpired = () => {}

  const handleSubmission = React.useCallback(
    (values, actions) => {
      // sanitize data before sending
      if (recaptchaRef.current.getValue()) {
        const sanitizedData = {
          email_address: _.trim(values.email),
          status: 'subscribed',
          language: 'en'
        }
        // submitting form value
        actions.setSubmitting(true)
        subscribeNewsletter(sanitizedData)
          .then(res => {
            setIsSuccess(true)
            setSuccessMessage(res.data.message)
          })
          .catch(error => {
            setHasError(true)
            if (error.response) {
              setErrorMessage(error.response.data)
            } else {
              setErrorMessage('Something wrong happened. Please try again later!!!')
            }
          })
          .finally(() => {
            actions.setSubmitting(false)
            actions.resetForm()
            setTimeout(() => {
              setHasError(false)
              setIsSuccess(false)
            }, 5000)
          })
      }
    },
    [recaptchaRef]
  )

  return (
    <div className="container-fluid px-0">
      <h1>NEWSLETTER</h1>
      <p className="mb-0">
        Subscribe to our newsletter to receive new updates on products, tutorials and promotions
      </p>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmission}
        validationSchema={NewsletterSchema}
        render={({ handleSubmit, isSubmitting, isValid }) => (
          <div>
            <Form noValidate className="attached fluid">
              <Grid stackable padded>
                <Grid.Row>
                  <Grid.Column>
                    <TextInput
                      name="email"
                      label="Email"
                      placeholder="example@domain.com"
                      autoComplete="email"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>
                {isValid && (
                  <Grid.Row>
                    <Grid.Column>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6Lc-mMMUAAAAALTsMCrCs5M54FZPK0GmTVqXIjaX"
                        onChange={handleSubmit}
                        onExpired={handleExpired}
                        badge="inline"
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
                {isSubmitting && <Progress percent={100} attached="top" active size="medium" />}
                <Grid.Row>
                  <Grid.Column>
                    <button
                      type="button"
                      className="ui button"
                      onClick={executeRecaptcha}
                      disabled={isSubmitting || !isValid}
                    >
                      Submit
                    </button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            {hasError && (
              <Message attached="bottom" negative>
                <Message.Header>Error!!!</Message.Header>
                {errorMessage}
              </Message>
            )}
            {isSuccess && (
              <Message attached="bottom" positive>
                <Message.Header>Thanks for subscribing to our newsletter!!!</Message.Header>
                {successMessage}
              </Message>
            )}
          </div>
        )}
      />
    </div>
  )
}

export default NewsletterForm
