import React from 'react'
import { Link } from 'gatsby'
import { Icon } from 'semantic-ui-react'
import classNames from 'classnames'
import Logo from '../images/logos/contego-logo.png'
import '../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss'

const Header = () => {
  // isActive is used to control menu isActive toggle
  const [isActive, setIsActive] = React.useState(false)
  const [activeMegaMenu, setActiveMegaMenu] = React.useState('')
  React.useEffect(() => {
    window.onclick = e => {
      if (
        !e.target.classList.contains('mega-menu') &&
        !e.target.classList.contains('mm-dropdown-btn') &&
        activeMegaMenu !== ''
      ) {
        setActiveMegaMenu('')
      }
    }

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 20) {
        document.getElementById('header').classList.add('position-fixed')
      } else {
        document.getElementById('header').classList.remove('position-fixed')
      }
    })
  })

  // handle megamenu click on desktop view
  const handleMegaMenuClick = React.useCallback(
    e => {
      const { name } = e.currentTarget.dataset
      if (activeMegaMenu === '') {
        setActiveMegaMenu(name)
      } else {
        setActiveMegaMenu('')
      }
    },
    [activeMegaMenu]
  )

  // toggle menu icon
  const toggleMenu = React.useCallback(() => {
    const navBarContainerElement = document.getElementById('mobile-nav-bar-container')
    setIsActive(isActive => !isActive)
    if (!isActive) {
      navBarContainerElement.style.left = '0'
    } else {
      navBarContainerElement.style.left = '-100%'
    }
  }, [isActive])

  return (
    <div className="row">
      <div className={classNames('header', 'shadow-sm py-2 px-2')} id="header">
        <div className="px-2">
          <Link to="/">
            <img src={Logo} alt="contego logo" className="m-0" width="140px" height="auto" />
          </Link>
        </div>

        <ul className={classNames('nav-bar', 'd-none d-xl-flex align-items-center')}>
          <li className="nav-bar-item">
            <Link activeClassName="active-nav-bar-item" className="nav-bar-link" to="/">
              HOME
            </Link>
          </li>

          <li className="nav-bar-item">
            <Link
              activeClassName="active-nav-bar-item"
              className="nav-bar-link"
              to="/pedicure-spa-chairs/majestic"
            >
              PEDICURE MASSAGE CHAIRS
            </Link>
          </li>

          <li className="nav-bar-item">
            <Link activeClassName="active-nav-bar-item" className="nav-bar-link" to="/accessories">
              MASSAGE CHAIR ACCESSORIES
            </Link>
          </li>

          <li className="nav-bar-item">
            <Link activeClassName="active-nav-bar-item" className="nav-bar-link" to="/about">
              ABOUT
            </Link>
          </li>

          <li className="nav-bar-item">
            <Link activeClassName="active-nav-bar-item" className="nav-bar-link" to="/contact-us">
              CONTACT
            </Link>
          </li>

          <li className="nav-bar-item">
            <button
              type="button"
              data-name="support"
              className={classNames(['nav-bar-link', 'mm-dropdown-btn'])}
              onClick={handleMegaMenuClick}
            >
              SUPPORT
              <i
                data-name="support"
                aria-hidden="true"
                className={classNames([
                  'small icon',
                  activeMegaMenu === 'support' ? 'chevron up' : 'chevron down'
                ])}
                id="dropdown-icon-support-link"
                onClick={handleMegaMenuClick}
              />
            </button>

            {activeMegaMenu === 'support' && (
              <div
                className={classNames([
                  'mega-menu',
                  'shadow',
                  activeMegaMenu === 'support'
                    ? 'visible animated fadeInDown faster'
                    : 'invisible animated fadeOut slower'
                ])}
              >
                <h1 className="mr-4 mb-0">Support</h1>
                <ul
                  className={classNames('nav-bar', 'd-none d-md-flex d-xl-flex align-items-center')}
                >
                  <li className="nav-bar-item">
                    <Link
                      activeClassName="active-nav-bar-item"
                      className="nav-bar-link"
                      to="/faq/general"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-bar-item">
                    <Link
                      activeClassName="active-nav-bar-item"
                      className="nav-bar-link"
                      to="/manuals-and-downloads/novo"
                    >
                      Manuals & Downloads
                    </Link>
                  </li>
                  <li className="nav-bar-item">
                    <Link activeClassName="active-nav-bar-item" className="nav-bar-link" to="/ip">
                      IP
                    </Link>
                  </li>
                  <li className="nav-bar-item">
                    <Link
                      activeClassName="active-nav-bar-item"
                      className="nav-bar-link"
                      to="/purchasetermsandconditions"
                    >
                      Purchase Terms & Conditions
                    </Link>
                  </li>
                  <li className="nav-bar-item">
                    <Link
                      activeClassName="active-nav-bar-item"
                      className="nav-bar-link"
                      to="/limitedwarranty"
                    >
                      Limited Warranty
                    </Link>
                  </li>
                  <li className="nav-bar-item">
                    <Link
                      activeClassName="active-nav-bar-item"
                      className="nav-bar-link"
                      to="/privacypolicy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-bar-item">
                    <Link
                      activeClassName="active-nav-bar-item"
                      className="nav-bar-link"
                      to="/shippingterms"
                    >
                      Shipping Terms
                    </Link>
                  </li>
                  <li className="nav-bar-item">
                    <Link
                      activeClassName="active-nav-bar-item"
                      className="nav-bar-link"
                      to="/deliveryandhook-upservice"
                    >
                      Delivery & Hook-up Service
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </li>

          <li className="nav-bar-item">
            <Link activeClassName="active-nav-bar-item" className="nav-bar-link" to="/gallery">
              GALLERY
            </Link>
          </li>

          <li className="nav-bar-item">
            <Link activeClassName="active-nav-bar-item" className="nav-bar-link" to="/videos">
              VIDEOS
            </Link>
          </li>
        </ul>

        <ul className={classNames('nav-bar', 'd-none d-xl-flex float-right')}>
          <li className="nav-bar-item">
            <a
              className={classNames(
                'm-action-btn',
                'px-4 py-2 my-2 rounded-pill text-white shadow'
              )}
              href="tel:7148958865"
            >
              714-895-8865 <Icon name="phone" className="m-0" />
            </a>
          </li>
        </ul>

        <div className="d-xl-none d-flex flex-wrap align-items-center">
          <a
            className="d-xl-none d-flex flex-column m-action-btn rounded-circle shadow"
            href="tel:7148958865"
          >
            <Icon name="phone" className="m-0" size="big" />
          </a>

          <button
            className={classNames('hamburger hamburger--emphatic d-xl-none rounded', {
              'is-active': isActive
            })}
            type="button"
            onClick={toggleMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </div>

      <div
        className="mobile-nav-bar-container shadow"
        hidden={!isActive}
        id="mobile-nav-bar-container"
      >
        <ul className="mobile-nav-bar p-0">
          <li className="mobile-nav-bar-item">
            <Link activeClassName="active-nav-bar-item" className="mobile-nav-bar-link" to="/">
              HOME
            </Link>
          </li>

          <li className="mobile-nav-bar-item">
            <Link
              activeClassName="active-nav-bar-item"
              className="mobile-nav-bar-link"
              to="/pedicure-spa-chairs/majestic"
            >
              PEDICURE MASSAGE CHAIRS
            </Link>
          </li>

          <li className="mobile-nav-bar-item">
            <Link
              activeClassName="active-nav-bar-item"
              className="mobile-nav-bar-link"
              to="/accessories"
            >
              MASSAGE CHAIR ACCESSORIES
            </Link>
          </li>

          <li className="mobile-nav-bar-item">
            <Link activeClassName="active-nav-bar-item" className="mobile-nav-bar-link" to="/about">
              ABOUT
            </Link>
          </li>

          <li className="mobile-nav-bar-item">
            <Link
              activeClassName="active-nav-bar-item"
              className="mobile-nav-bar-link"
              to="/contact-us"
            >
              CONTACT
            </Link>
          </li>

          <li className="mobile-nav-bar-item">
            <button
              type="button"
              data-name="m-support"
              className={classNames('mobile-nav-bar-link', 'mm-dropdown-btn p-0')}
              onClick={handleMegaMenuClick}
            >
              SUPPORT
              <i
                data-name="m-support"
                aria-hidden="true"
                className={classNames([
                  'small icon mm-dropdown-btn',
                  activeMegaMenu === 'm-support' ? 'chevron up' : 'chevron down'
                ])}
                onClick={handleMegaMenuClick}
              />
            </button>

            {activeMegaMenu === 'm-support' && (
              <div
                className={classNames([
                  'mega-menu shadow-sm d-flex flex-column align-items-start px-4 py-2 rounded',
                  activeMegaMenu === 'm-support'
                    ? 'visible animated fadeInDown faster'
                    : 'invisible animated fadeOut slower'
                ])}
              >
                <Link
                  activeClassName="active-nav-bar-item"
                  className="mobile-dropdown-link"
                  to="/faq/general"
                >
                  FAQ
                </Link>
                <Link
                  activeClassName="active-nav-bar-item"
                  className="mobile-dropdown-link"
                  to="/manuals-and-downloads/novo"
                >
                  Manuals & Downloads
                </Link>
                <Link
                  activeClassName="active-nav-bar-item"
                  className="mobile-dropdown-link"
                  to="/ip"
                >
                  IP
                </Link>
                <Link
                  activeClassName="active-nav-bar-item"
                  className="mobile-dropdown-link"
                  to="/purchasetermsandconditions"
                >
                  Purchase Terms & Conditions
                </Link>
                <Link
                  activeClassName="active-nav-bar-item"
                  className="mobile-dropdown-link"
                  to="/limitedwarranty"
                >
                  Limited Warranty
                </Link>
                <Link
                  activeClassName="active-nav-bar-item"
                  className="mobile-dropdown-link"
                  to="/privacypolicy"
                >
                  Privacy Policy
                </Link>
                <Link
                  activeClassName="active-nav-bar-item"
                  className="mobile-dropdown-link"
                  to="/shippingterms"
                >
                  Shipping Terms
                </Link>
                <Link
                  activeClassName="active-nav-bar-item"
                  className="mobile-dropdown-link"
                  to="/deliveryandhook-upservice"
                >
                  Delivery & Hook-up Service
                </Link>
              </div>
            )}
          </li>

          <li className="mobile-nav-bar-item">
            <Link
              activeClassName="active-nav-bar-item"
              className="mobile-nav-bar-link"
              to="/gallery"
            >
              GALLERY
            </Link>
          </li>

          <li className="mobile-nav-bar-item">
            <Link
              activeClassName="active-nav-bar-item"
              className="mobile-nav-bar-link"
              to="/videos"
            >
              VIDEOS
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Header
