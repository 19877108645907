import React from 'react'
// prop-types is for props validation
import PropTypes from 'prop-types'
// own build components
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <div className="container-fluid">
      <Header />

      <div className="container-fluid p-0 bg-white">{children}</div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
